<template>
  <div>
    <el-page-header @back="dynamic" content="内容管理" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text">
        <el-button size="mini" type="primary" @click="editRelease(undefined)">新增</el-button>
        <el-button size="mini" type="primary" @click="getNewsList">全部显示</el-button>
        <el-select v-model="value" clearable placeholder="选择模块" size="mini"
                   style="margin-left: 20px; width: 130px; margin-right: 20px"
                   @change="selectModular"
                    @clear="getNewsList">
          <el-option
              v-for="item in dictionaryData"
              :key="item.value"
              :label="item.dictionaryName"
              :value="item.value"
              >
            <span>{{ item.dictionaryName }}</span>
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" :disabled="disabled" @click="paixu">排序</el-button>
        <el-button size="mini" type="primary" :disabled="disabled1" @click="editPaixu">确定</el-button>
      </el-button>
    </template>

    <el-table
        :data="newsData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}"
        min-height="456">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="newsTitle"
          label="标题">
        <template slot-scope="scopeNews">
          <el-link @click="onToNewsReader(scopeNews.row.newsId)">{{scopeNews.row.newsTitle}}</el-link>
        </template>
      </el-table-column>
      <el-table-column
          prop="hits"
          label="浏览量"
          width="70">
      </el-table-column>
      <el-table-column
          prop="newsIndex"
          label="排序"
          width="50">
        <template slot-scope="paixu">
          <span v-if="disabled2 == false">{{ paixu.row.newsIndex }}</span>
          <input v-if="disabled2" size="3" v-model="paixu.row.newsIndex" style="width: 100%">
        </template>
      </el-table-column>
      <el-table-column
          prop="modular"
          label="板块"
          width="80">
        <template slot-scope="scopeModular">
          <el-tag v-if="(scopeModular.row.modular === 3)"  type="danger" size="mini">创作研究</el-tag>
          <el-tag v-if="(scopeModular.row.modular === 1)" size="mini">中心新闻</el-tag>
          <el-tag v-if="(scopeModular.row.modular === 2)" type="warning" size="mini">学术交流</el-tag>
          <el-tag v-if="(scopeModular.row.modular === 4)" type="success" size="mini">人才队伍</el-tag>
          <el-tag v-if="(scopeModular.row.modular === 5)" type="warning" size="mini">业界动态</el-tag>
          <el-tag v-if="(scopeModular.row.modular === 6)" type="info" size="mini">通知公告</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="isOpen"
          label="显示"
          width="48">
        <template slot-scope="scopeOpen">
          <el-tag v-if="(scopeOpen.row.isOpen === 0)"  type="danger" size="mini">私有</el-tag>
          <el-tag v-if="(scopeOpen.row.isOpen === 1)" size="mini">公开</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="updateTime"
          label="更新日期"
          width="160">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="80">
        <template slot-scope="scope">
          <el-button
                     @click.native.prevent="deleteRelease(scope.row.newsId)"
                     style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
          <el-button
              @click.native.prevent="editRelease(scope.row.newsId)"
              style="padding: 2px; margin: 5px 0px">
            <i class="el-icon-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "release",
  data() {
    return {
      disabled: true,
      disabled1: true,
      disabled2: false,
      // disabled3: true,
      formLabelWidth: '120px',
      newsData: [],
      dictionaryData: [],
      value: ''
    }
  },
  mounted() {
    this.getNewsList()
    this.getEditDictionaryByModular(2)
    // this.openFullScreen()
  },
  methods: {
    paixu(){
      this.disabled1 = false
      this.disabled2 = true
    },
    editPaixu() {
      this.disabled2 = false
      this.disabled1 = true
      let newsPaixu = []
      for (var i = 0; i < this.newsData.length; i++) {
        newsPaixu.push({newsId:this.newsData[i].newsId,newsIndex:+this.newsData[i].newsIndex})
      }
      this.$http({
        method: 'post',
        url: '/api/news/editIndex',
        data: newsPaixu
      }).then((res) =>{
        console.log(res);
      })
    },
    dynamic() {
      this.$router.push("dynamic")
    },
    editRelease(newsId) {
      this.$router.push({
        path: "editer",
        query: {newsId: newsId}
      })
    },
    deleteRelease(newsId) {
      this.$confirm('删除操作将不可逆' , '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.$http({
          method: 'delete',
          url: '/api/news/delNewsById/' + newsId,
        }).then((res) =>{
          if (res.data.status) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.getNewsList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
      }).catch(()=>{
        this.$message({
          type: 'error',
          message: "系统异常"
        })
      })
    },
    getNewsList() {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$http({
        method: 'get',
        url: '/api/news/getNewsList'
      }).then((res) =>{
        this.newsData = res.data.data
        this.disabled = true
        this.disabled1 = true
        this.disabled2 = false
        if (res.data.status) loading.close();
        setTimeout(()=>{loading.close()},5000)
      })
    },
    onToNewsReader(newsId) {
      this.$router.push({
        path: '/reader/',
        query: {id: newsId,type: '1'}
      })
    },
    getEditDictionaryByModular (editModular) {
      this.$http({
        method: 'get',
        url: '/api/dictionary/getDictionaryByModular/' + editModular
      }).then((res) =>{
        this.dictionaryData =res.data.data
      })
    },
    selectModular(modular) {
      if (modular === "") return;
      this.$http({
        method: 'get',
        url: '/api/news/getNewsListByModular/' + modular
      }).then((res) =>{
        this.newsData = res.data.data
        this.disabled = false
      })
    },
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>